import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import QRCode from 'qrcode';

import Section from '../Section';
import Img from '../Img';

function QrCodeDrawer ({ search, text }) {
  const [ src, setSrc ] = useState('');
  // const { theme } = useTheme();
  const { originSearchText, destinationSearchText } = search;

  const generate = () => {
    const opts = {
      errorCorrectionLevel: 'H',
      type: 'image/jpeg',
      width: 600,
      rendererOpts: {
        quality: 0.3,
      },
    };

    const text = window.location.href;

    QRCode.toDataURL(text, opts, (error, url) => {
      if (error) throw new Error(error);
      setSrc(url);
    });
  };

  useEffect(() => {
    generate();
  }, []);

  useEffect(() => {
    generate();
  }, [originSearchText, destinationSearchText]);

  return (
    <Section className='card-qrcode' vertical={false}>
      <div className='card-qrcode-left'>
        <a href={src} download={`${destinationSearchText}.jpg`}>
          <Img
            key={search?.destinationShop?.id || 'qrcode'}
            className='img'
            src={src}
            style={{ maxWidth: '100%' }}
          />
        </a>
      </div>
      <div className='card-qrcode-right'>{text}</div>
    </Section>
  );
}

QrCodeDrawer.propTypes = {
  text: PropTypes.string,
  search: PropTypes.shape({
    originSearchText: PropTypes.string,
    destinationSearchText: PropTypes.string,
  }),
};

QrCodeDrawer.defaultProps = {
  text: '',
  search: {
    originSearchText: '',
    destinationSearchText: '',
  },
};

export default QrCodeDrawer;
