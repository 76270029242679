import entity from "~/store/modules/Entity";
import { actionFetcher, intersection, slugify, isEmpty } from "~/utils";
import contentService from "~/apis/Content";

const verifyIsOmbresJson = (projectJson) => {
  const firstShop = projectJson.shops[Object.keys(projectJson.shops)[0]];
  return firstShop.name !== undefined && firstShop.title === undefined;
};
const verifyIsUgdJson = (projectJson) => {
  const firstShop = projectJson.shops[Object.keys(projectJson.shops)[0]];
  return firstShop.title !== undefined;
};

// const toUgdShop = (ombresShop) => {
//   return {
//     id: '',
//     shopId: '',
//     title: {
//       th: 'จีน จีน',
//       en: 'JEANJEAN'
//     },
//     description: {
//       th: 'จีน จีน,อุปกรณ์เดินป่า,เสื้อผ้า หมวกลายทหาร,แว่น',
//       en: ''
//     },
//     slug: 'jeanjean-5c9',
//     keywords: [
//       'จีน',
//       'อุปกรณ์',
//       'เดิน',
//       'ป่า',
//       'เสื้อ',
//       'ผ้า',
//       'หมวก',
//       'ลาย',
//       'ทหาร',
//       'แว่น',
//       'JEANJEAN'
//     ],
//     categories: [
//       '62',
//       '68'
//     ],
//     floorId: '103',
//     floor: 'fashionisland-3',
//     contact: {
//       email: '',
//       website: '',
//       facebook: '',
//       instagram: '',
//       phone: ''
//     },
//     contract: [
//       '2019-01-01',
//       '2070-03-16'
//     ],
//     ownerId: null,
//     projectId: 1,
//     entityTypeId: 1,
//     gallery: [],
//     logo: '',
//     unit: 'DC09',
//     node: '',
//     status: 'published'
//   }
// };

export default {
  /**
   * Get content by provided project id
   *
   * @param {String} projectId
   * @return {Function}
   */
  getContentByProjectId(projectId, onDownloadProgress) {
    return actionFetcher({
      loadingMessage: "Fetching content from dc",
      successMessage: "Success",
      async callAction(dispatch) {
        const { data } = await contentService.getContentByProjectId(
          projectId,
          onDownloadProgress
        );

        const isOmbresJson = verifyIsOmbresJson(data);
        const isUgdJson = verifyIsUgdJson(data);

        console.debug("checking project.json version", {
          data,
          isOmbresJson,
          isUgdJson,
        });

        const disableCategories = [199, 299];

        if (Array.isArray(data.categories)) {
          // transform categories array to object
          // and remove categories that contain category 101 (department-stores), 113 (education)
          data.categories = data.categories.reduce((acc, category) => {
            if (disableCategories.indexOf(category.id) === -1) {
              return {
                ...acc,
                [category.id]: {
                  ...category,
                  name: category.title,
                },
              };
            }
            return acc;
          }, {});
        }
        if (Array.isArray(data.shops)) {
          // transform shop array to object
          // and remove shops that contain category 101 (department-stores), 113 (education)
          data.shops = data.shops.reduce((acc, shop, index) => {
            if (
              intersection(shop.categories, disableCategories).length <= 0 &&
              !isEmpty(shop.floor)
            ) {
              const shopUniqueId = shop.nid || shop.id || shop.slug || index;
              return {
                ...acc,
                [`${shopUniqueId}`]: {
                  ...shop,
                  name: shop.title,
                },
              };
            }
            return acc;
          }, {});
        }
        if (isOmbresJson) {
          const { medias } = data;

          // add default building floor
          data.building = Object.keys(data.building).reduce((acc, buildingKey) => {
            const building = data.building[buildingKey];
            let defaultFloor = null;
            switch (buildingKey) {
              case 'a-la-art':
                defaultFloor = 'A LA ART 1';
                break;
              case 'mbk':
                defaultFloor = '1';
                break;
              default:
                break;
            }
            return {
              ...acc,
              [buildingKey]: {
                ...building,
                slug: building.slug,
                defaultFloor,
              },
            };
          }, {});

          // add slug to floor
          data.floors = Object.keys(data.floors).reduce((acc, floorKey) => {
            const floor = data.floors[floorKey];
            return {
              ...acc,
              [floorKey]: {
                ...floor,
                slug: floor.id,
              },
            };
          }, {});
          data.categories = Object.keys(data.categories || {}).reduce(
            (acc, categoryKey) => {
              const category = data.categories[categoryKey];
              return {
                ...acc,
                [categoryKey]: {
                  id: categoryKey,
                  slug: categoryKey,
                  name: category.name,
                  title: category.name,
                  parent: category.parent || null,
                  parentId: category.parent || null,
                  projectId: 1,
                  fill: category.tags[0] || null,
                },
              };
            },
            {}
          );
          // transform category and subcategory to categories = [category, subcategory]
          data.shops = Object.keys(data.shops).reduce((acc, index) => {
            const shop = data.shops[index];
            const shopUniqueId = shop.nid || shop.id || shop.slug || index;
            const validShop = !isEmpty(shop.floor);
            if (!validShop) {
              console.log(shop.name.en, "is invalid.", shop);
              return acc;
            }
            const logo = (medias[shop.logo] && medias[shop.logo].url) || "";
            const unit =
              (shop.units && shop.units[0] && shop.units[0].unitNo) || "";
            const galleries = shop.gallery
              ? shop.gallery.map(
                  (mediaKey) => (medias[mediaKey] && medias[mediaKey].url) || ""
                )
              : [];
            const categories = [];
            if (shop.category) {
              if (!isNaN(Number(shop.category))) {
                categories.push(Number(shop.category));
              } else {
                categories.push(shop.category);
              }
            }
            if (shop.subcategory) {
              if (!isNaN(Number(shop.subcategory))) {
                categories.push(Number(shop.subcategory));
              } else {
                categories.push(shop.subcategory);
              }
            }
            // console.log('shop.name.en', shop.name.en);
            const slug = shopUniqueId;
            return {
              ...acc,
              [slug]: {
                ...shop,
                slug,
                title: shop.name,
                keywords: shop.tags,
                categories,
                unit,
                logo,
                gallery: galleries,
              },
            };
          }, {});
        }

        // remove non-node / non-unit shop
        const { floors, shops, nodes, categories } = data;

        const missingUnitShops = {};
        const floorNotMatched = [];

        Object.keys(shops).map((shopKey) => {
          const shop = shops[shopKey];
          if (!shop.name.en || !shop.name.th) {
            console.log(shop.unit, "en:", shop.name.en, "th", shop.name.th);
          }
          const floorData = floors[shop.floor];
          if (floorData) {
            const isNodeExist = shop.node && nodes[shop.node];
            const isUnitExist = floorData.units.find(
              (unit) => unit.id === shop.unit
            );
            if (!isNodeExist) {
              shop.nodeExist = false;
            } else {
              shop.nodeExist = true;
            }
            if (!isUnitExist) {
              shop.unitExist = false;
            } else {
              shop.unitExist = true;
            }
            if (!isNodeExist || !isUnitExist) {
              if (missingUnitShops[shop.floor]) {
                missingUnitShops[shop.floor] = [
                  ...missingUnitShops[shop.floor],
                  shop,
                ];
              } else {
                missingUnitShops[shop.floor] = [shop];
              }
              delete shops[shopKey];
            }
          } else {
            floorNotMatched.push(shop)
            delete shops[shopKey];
          }
        });

        /* eslint-disable */
        Object.keys(missingUnitShops).map((floorKey) => {
          console.log("============", floorKey, "============");
          missingUnitShops[floorKey].map((shop) => {
            console.log(
              `${shop.unit} : ${shop.name.th}  ${shop.name.en} ${
                !shop.nodeExist ? "X node" : ""
              } ${!shop.unitExist ? "X unit" : ""}`
            );
          });
          console.log("Total :", missingUnitShops[floorKey].length);
        });
        /* eslint-enable */

        const categoryList = Object.keys(categories);
        // filtered shop.category not found in data
        Object.keys(shops).map((shopKey) => {
          const shop = shops[shopKey];
          if (!categoryList.includes(shop.categories[0])) {
            console.log('Filtered out unit :', shop.unit, `- ${shop.categories}`);
            delete shops[shopKey];
          }
          if (shop.tags[0] == "10-things") {
            console.log('Filtered out unit :', shop.unit, `- 10-things`);
            delete shops[shopKey];
          }
        });

        floorNotMatched.map((shop) => {
          console.log(`${shop.unit} floor ${shop.floor} not found`)
        })
        
        dispatch(entity.setEntity({ payload: data }));
        return data;
      },
    });
  },
  /**
   * Get floor icon
   *
   * @returns
   * @function
   */
  getFloorIcon(projectId) {
    return actionFetcher({
      loadingMessage: "Fetching floor icon from mockup data",
      successMessage: "Success fetching floor icon",
      async callAction(dispatch) {
        const floorIcon = await contentService.getFloorIcon(projectId);
        if (floorIcon) {
          dispatch(entity.setFloorIcon({ payload: floorIcon }));
        }
        return floorIcon;
      },
    });
  },
  /**
   * Get movement media
   *
   * @returns
   * @function
   */
  getMovementMedia() {
    return actionFetcher({
      loadingMessage: "Fetching movement media from mockup data",
      successMessage: "Success fetching movement media",
      async callAction(dispatch) {
        const movementMedia = await contentService.getMovementMedia();
        dispatch(entity.addMedia({ payload: movementMedia }));

        return movementMedia;
      },
    });
  },
};
