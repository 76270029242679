import { createSelector } from 'reselect';
import helper from '../helper';

/**
 * Entire kiosk
 * @description entire kiosk from redux store
 */
const entire = helper.getEntireKiosk;

/**
 * Kiosks
 * @description Entire kiosk data which convert to an array
 */
const kiosks = createSelector(
  [entire],
  entire => entire && Object.entries(entire).map(kiosk => ({ id: kiosk[0], ...kiosk[1] })),
);

export default {
  entire,
  kiosks,
};
