import helper from '../helper';

/**
 * Entire
 * @description entire setting from redux store
 */
const entire = helper.getEntireSetting;

export default {
  entire,
};
