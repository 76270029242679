import { useContext } from 'react';
import { TranslationContext } from '~/context/translation';

/**
 * ---
 * useTranslation hook
 * ---
 */
const useTranslation = () => {
  const { languages, currentLanguage, setCurrentLanguage } = useContext(TranslationContext);

  /**
   * Change language
   *
   * @param {String} targetLanguage
   * @return void
   */
  const changeLanguage = (targetLanguage) => {
    if (languages[targetLanguage]) {
      setCurrentLanguage(targetLanguage);
      return;
    }
    console.error(`not found "${targetLanguage}" language did you provide`);
  };

  /**
   * Translate
   *
   * @param {( 'string' | 'object' )} key
   * @return {String}
   */
  const translate = (key) => {
    if (typeof key === 'undefined' || key[currentLanguage] === null) {
      return undefined;
    }

    if (typeof key === 'string') {
      const language = languages[currentLanguage];
      return language[key];
    }

    if (key[currentLanguage].length <= 0) {
      const foundText = Object.values(key).filter(Boolean)[0];
      return foundText || '';
    }

    return key[currentLanguage];
  };

  return {
    currentLanguage,
    changeLanguage,
    translate,
  };
};

export default useTranslation;
