import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import useRouter from 'use-react-router';

import { useSelfBBox } from '~/hooks';
import { getFile, getEllipseText } from '~/utils';
import queryString from 'query-string'
import { useHistory, useLocation } from 'react-router';
import { useCallback } from 'react';
import { isMobile } from 'react-device-detect';

function PopupShopOverview ({
  x, y, logo, title, subTitle, text, id
}) {
  const floor = window.location.pathname.split('$').slice(-1)[0]
  const { match: { params: { projectId, lang, destination } } } = useRouter();
  const history = useHistory();
  const handleClickX = useCallback(() => {
    history.push(`/${lang}/${projectId}/explore${floor ? `?floor=${floor}` : ''}`);
  })
  const { selfRef, selfSize } = useSelfBBox();
  return (
    <Wrapper ref={selfRef} x={x - (selfSize.width / 2)} y={y - (selfSize.height)}>
      <g id="Group" pointerEvents="bounding-box">
        <g data-name="Layer 1">
          <g data-name="Group 7210">
            <g data-name="Group 7208">
              <path
                fill="#fff"
                stroke="#707070"
                strokeWidth="0.25"
                d="M 103 96 H 4.58 a 4.46 4.46 0 0 1 -4.46 -4.45 V 4.58 A 4.46 4.46 0 0 1 4.58 0.12 H 216 a 4.46 4.46 0 0 1 4.5 4.46 v 86.76 a 4.45 4.45 0 0 1 -4.45 4.45 H 117 l -7 12.21 z"
                data-name="Union 1-2"
              />
              <path
                fill="none"
                stroke="#707070"
                strokeWidth="0.25"
                d="M8.25 6.47H89.95V88.17H8.25z"
                data-name="709px-H M-Logo"
              />
              <g data-name="Group 7173">
                <g data-name="Group 387">
                  <text
                    transform="translate(98.80 24.17)"
                    style={{ isolation: 'isolate' }}
                    data-name="H M"
                    fontFamily="DB Adman X"
                    fontSize="19"
                    // letterSpacing=".1px"
                  >
                    {getEllipseText(title, 12)}
                  </text>
                  <text
                    transform="translate(98.80 39.96)"
                    style={{ isolation: 'isolate' }}
                    fill="#8f8f8f"
                    data-name="FASHION ACCESSORIES"
                    fontFamily="DB Adman X"
                    fontSize="13"
                    fontWeight="300"
                    // letterSpacing="-.06em"
                  >
                    {getEllipseText(subTitle, 25)}
                  </text>
                </g>
              </g>
              <g data-name="Group 7174">
                <text
                  transform="translate(98.80 82.61)"
                  style={{ isolation: 'isolate' }}
                  fill="#6a6a6a"
                  data-name="1ST FLOOR"
                  fontFamily="DB Adman X"
                  fontSize="18"
                  // letterSpacing=".075em"
                >
                  {getEllipseText(text, 15)}
                </text>
              </g>
            </g>
          </g>
          <g>
            <image
              width="224"
              height="225"
              data-name="Caknjg"
              transform="matrix(.36 0 0 .36 8.81 7.13)"
              xlinkHref={logo || getFile(`/images/${projectId}/shop-no-logo.png`)}
            />
          </g>
          {/* x button */}
          { (destination && !isMobile ) &&
            <g data-name="Group 7175">
              <text
                transform="translate(200.80 20.61)"
                style={{ isolation: 'isolate' }}
                fill="#e00000"
                data-name="1ST FLOOR"
                fontFamily="DB Adman X"
                fontSize="15"
                fontWeight="700"
                onClick={handleClickX}
              >
                ✕
              </text>
            </g>
          }
        </g>
      </g>
    </Wrapper>
  );
}

PopupShopOverview.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  logo: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  text: PropTypes.string,
};

PopupShopOverview.defaultProps = {
  x: 0,
  y: 0,
  logo: '',
  title: 'Title',
  subTitle: 'Subtitle',
  text: '',
};

const Wrapper = styled.g`
  transform: ${({ x, y }) => `translate(${x}px, ${y}px)`};
`;

export default PopupShopOverview;
