import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { useTheme } from '~/hooks';

import Icon from '../Icon';

import { bindingClassName } from '~/utils';

/**
 * ----------------------------------------------------------------------------
 * Shop title component
 * ----------------------------------------------------------------------------
 *
 * @param {{ title: String, subTitle: String, floor: {}, image: String }} props
 * @param {String} props.title
 * @param {String} props.subTitle
 * @param {{}} props.floor
 * @param {String} props.string
 * @return {JSX.Element}
 */
const ShopTitle = ({
  title,
  subTitle,
  location,
  openingHours,
  floor,
  unitNo,
}) => {
  const { theme } = useTheme();

  const renderOpeningHours = () => {
    const isOpening = true;
    let suggestionText = '';
    if (isOpening) {
      const closeTime = '21:00';
      suggestionText = `UNTIL ${closeTime} TODAY`;
    } else {
      const openTime = '10:00';
      suggestionText = `OPEN AT ${openTime} TOMORROW`;
    }
    return (
      <span>
        <span className={bindingClassName('icon', { 'is-open': isOpening, 'is-closed': !isOpening })}>
          <Icon name="Clock" />
          {isOpening ? 'OPEN' : 'CLOSED'}
        </span>
        <span className="suggestion-text">{suggestionText}</span>

      </span>
    );
  };

  const getFloorText = () => {
    const floorText = floor === Array.isArray(floor) ? floor.join('|') : floor;
    return floorText;
  };

  return (
    <Wrapper theme={theme}>
      <div className="header-content">
        <div className="header-content-block">
          <p className="title">
            {title}
          </p>
          {/* <p className="opening-hours">
            {renderOpeningHours()}
          </p> */}
        </div>
        <div className="header-content-block">
          <p className="sub-title">
            {subTitle}
          </p>
        </div>
        <div className="header-content-block">
          <p className="text-floor">
            <Icon className="icon-location" name="MapMarkerAlt" />
            <span>{`${location} ${getFloorText()} ${unitNo.length > 0 ? `(${unitNo})` : ''}`}</span>
            <span>{}</span>
            <span>{}</span>
          </p>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
    .icon-location {
      color: ${({ theme }) => theme.colorHelper.secondary};
    }
    .sub-title {
      color: ${({ theme }) => theme.colorHelper.greyLight};
    }
    .text-floor {
      color: ${({ theme }) => theme.colorHelper.greyLight};
    }
`;

ShopTitle.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  location: PropTypes.string,
  openingHours: PropTypes.arrayOf(PropTypes.shape({

  })),
  floor: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  unitNo: PropTypes.string,
};
ShopTitle.defaultProps = {
  title: '',
  subTitle: '',
  location: '',
  openingHours: [],
  floor: '',
  unitNo: '',
};


export default ShopTitle;
