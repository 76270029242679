import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import {
  useAction,
  useSelector,
  useTranslation,
  useRouter,
  useTheme,
} from '~/hooks';

import searchAction from '~/store/actions/Search';

import {
  shopSelector,
  searchSelector,
  categorySelector,
  mediaSelector,
  floorSelector,
  domainSelector,
} from '~/store/selector';

import { slugify } from '~/utils';

import { Section, ParagraphTitle, Column, ShopNearByCard } from '~/components';

function ShopYouMightLike ({ shopsYouMightLikeAmount }) {
  const actions = useAction(searchAction);
  const { push } = useRouter();
  const { translate } = useTranslation();
  const {
    domain,
    search,
    getRelatedShopsOfShop,
    getCategoryById,
    getFloorById,
  } = useSelector({
    search: searchSelector.entire,
    domain: domainSelector.entire,
    getRelatedShopsOfShop: shopSelector.getRelatedShopsOfShop,
    getCategoryById: categorySelector.getCategoryById,
    getMediaById: mediaSelector.getMediaById,
    getFloorById: floorSelector.getFloorById,
  });

  const [ randomShops, setRandomShops ] = useState([]);
  const [ category, setCategory ] = useState({});

  const { projectId } = domain;
  const { destinationShop } = search;

  const { theme } = useTheme();
  const { currentLanguage } = useTranslation();

  useEffect(() => {
    const relatedShop = getRelatedShopsOfShop(destinationShop, shopsYouMightLikeAmount).filter(shop => shop.category === destinationShop.category)
    setRandomShops(relatedShop);
    setCategory(getCategoryById(destinationShop.category) || {});
  }, [destinationShop]);

  /**
   * Handle on click shop nearby
   */
  const handleOnClickShopNearby = (shop) => {
    const shopName = translate(shop.name);
    actions.setDestination({
      destinationSearchText: shopName,
      destinationShop: shop,
    });

    const regexPath = '/:lang/:projectId/explore/:destination';
    push({
      regexPath,
      params: {
        projectId,
        lang: currentLanguage,
        destination: `${slugify.encode(shopName)}$${shop.floor}`,
      },
    });
  };

  if (randomShops.length <= 0) return <div />;

  return (
    <Wrapper className="shop-you-might-like has-padding-layout" theme={theme}>
      <ParagraphTitle
        head={(
          <div>
            <h2 className="title">{`${translate('shopYouMightLike')} ${randomShops.length}`}</h2>
          </div>
        )}
      />
      <Section horizontal>
        <Column mobileScrollable>
          {randomShops.map((shop) => {
            const floor = getFloorById(shop.floor);
            return (
              <Column.Item key={shop.id}>
                <ShopNearByCard
                  logo={shop.logo}
                  title={translate(shop.name)}
                  subTitle={translate(category.name)}
                  floor={{
                    name: floor && translate(floor.fields.name),
                    id: floor && (Number.parseInt(floor.id) || floor.id),
                  }}
                  onClick={() => handleOnClickShopNearby(shop)}
                />
              </Column.Item>
            );
          })}
        </Column>
      </Section>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .column {
    &.is-mobile-scrollable {
      flex-direction: row;
      flex-wrap: nowrap;
      overflow-x: auto;
    }
    &::-webkit-scrollbar {
      display: none;
    }
  }

`;

ShopYouMightLike.propTypes = {
  shopsYouMightLikeAmount: PropTypes.number,
};
ShopYouMightLike.defaultProps = {
  shopsYouMightLikeAmount: 100,
};

export default ShopYouMightLike;
