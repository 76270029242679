import React from 'react';
import styled from '@emotion/styled';

import { useTranslation, useTheme } from '~/hooks';
/**
 * ----------------------------------------------------------------------------
 * Filter category component
 * ----------------------------------------------------------------------------
 *
 * @returns
 */
function HeroText () {
  const { translate } = useTranslation();
  const { theme } = useTheme();

  const { line, palette } = theme.heading;

  const paletteSize = palette.length;

  const renderMainText = () => {
    const mainText = translate('exploreStore').split('')
    let j = 0;
    return _.map(mainText, (char, i) => {
      const colorIndex = j % paletteSize;
      const colorHex = palette[colorIndex];
      char = (mainText[i+1] === '\u0E49') ? [mainText[i], mainText[i+1]].join().replace(',', '') : char
      j = (char !== ' ') ? j += 1 : j
      return (char === '\u0E49') ? false : (
        <span key={`${char}-${i}`} style={{ color: colorHex }}>
          {char}
        </span>
      );
    })
  };

  return (
    <Wrapper className="hero-text">
      <div className="hero-text-line-container">
        <Line className="line" color={line} />
      </div>
      <div className="hero-text-container">
        <div className="text"><span>{renderMainText()}</span></div>
        <div className="text"><span>&</span></div>
        <div className="text"><span>{translate('wayFinding')}</span></div>
      </div>
      <div className="hero-text-line-container">
        <Line className="line" color={line} />
      </div>
    </Wrapper>
  );
}

HeroText.propTypes = {
};

HeroText.defaultProps = {
};

const Wrapper = styled.div`
  /* margin: 20px 0; */
`;

const Line = styled.div`
  border: ${({ color }) => `1px solid ${color}`};
`;

export default HeroText;
