import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { usePalette } from 'react-palette';
import _ from 'lodash';

import { useTheme } from '~/hooks';
import { bindingClassName } from '~/utils';

/**
 * ----------------------------------------------------------------------------
 * Floor Item component
 * ----------------------------------------------------------------------------
 *
 * @param {{
 * }} props
 * @returns
 */
function FloorItem ({ name, className, floorKey, active, onClick }) {
  const { theme } = useTheme();
  const floorColors = theme.floorColors || {};
  const floorIcons = theme.floorIcons || {};
  // pin add more for edit error
  const nowFloorKey = (_.size(_.split(floorKey, 'A LA ART ')) > 1) ? Number(_.split(floorKey, 'A LA ART ')[1]) : floorKey;
  const src = floorIcons[nowFloorKey] || '';
  const inactiveIcon = _.get(floorIcons, `${nowFloorKey}-inactive`) || '';
  const color = floorColors[nowFloorKey] || '#FFF';
  const palette = usePalette(src);
  const handleClick = (e) => {
    e.preventDefault();
    onClick();
  };
  return (
    <Wrapper
      theme={theme}
      palette={palette}
      colorActive={color.active}
      colorDefault={color.default}
      colorBackground={color.background}
      className={bindingClassName(
        `floor-item ${className}`,
        { active },
      )}
      onClick={handleClick}
    >
      {
        _.isEmpty(src) ? (
          <h2 className="floor-name">{name}</h2>
        ) : <img src={active ? src : inactiveIcon} alt="" />
      }
    </Wrapper>
  );
}

FloorItem.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  floorKey: PropTypes.string,
  active: PropTypes.bool,
  onClick: PropTypes.func,
};

FloorItem.defaultProps = {
  name: '',
  className: '',
  floorKey: '1',
  active: false,
  onClick () {},
};

const Wrapper = styled.div`
  background: ${({ colorBackground }) => colorBackground};
  border-radius: ${({ theme }) => theme.floorBorderRadius || '4px'};
  .floor-name {
    color: ${({ theme }) => theme?.floorNameColor?.default || '#000000'};
  }
  &.active {
    background: ${({ colorActive }) => colorActive};
    .floor-name {
      color: ${({ theme }) => theme?.floorNameColor?.active || '#000000'};
    }
  }
`;

export default FloorItem;
