import React, { forwardRef, useImperativeHandle, useState, useReducer } from 'react';
import PropTypes from 'prop-types';
import { useSpring, animated } from 'react-spring';
import Hammer from 'react-hammerjs';
import { isMobile } from 'react-device-detect';
import styled from '@emotion/styled';

import { useTheme } from '~/hooks';
import { bindingClassName } from '~/utils';
import Icon from '../Icon';
import Img from '../Img';

/**
 * Reducer
 *
 * @param {{ start: Number, move: Number, current: Number, index: Number, isPanning: Boolean }} state
 * @param {{ type: String, payload: {} }} action
 * @returns
 */
const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'pan-start':
      return { ...state, ...payload, isPanning: true };

    case 'pan':
      return { ...state, ...payload };

    case 'pan-end':
      return { ...state, ...payload, isPanning: false };

    case 'set-index':
      return { ...state, index: parseInt(payload, 10), current: payload, move: payload };

    default:
      throw new Error('Unexpected action');
  }
};

/**
 * Initial value of reducer state
 * @constant
 */
const initialState = {
  start: 0,
  move: 0,
  current: 0,
  index: 0,
  isPanning: false,
};

/**
 * ----------------------------------------------------------------------------
 * Lightbox component
 * ----------------------------------------------------------------------------
 * @param {{ imgs: Array, title: String, onChange: Function, onClose: Function }} props
 * @param {{}} ref
 */
const Lightbox = forwardRef(({ title, imgs, onChange, onClose }, ref) => {
  const { theme } = useTheme();

  const [ visible, setVisible ] = useState(false);
  const [ toolBarVisible, setToolBarVisible ] = useState(true);
  const [ state, dispatch ] = useReducer(reducer, initialState);

  const [ animatedProp, setAnimated ] = useSpring(() => ({
    transform: 0,
    immediate: true,
  }));

  const toggle = (type, targetIndex) => {
    switch (type) {
      case 'open':
        setAnimated({ transform: parseInt(targetIndex, 10), immediate: true });
        dispatch({ type: 'set-index', payload: targetIndex });
        setVisible(true);
        break;

      case 'close':
        setVisible(false);
        break;

      default:
        setVisible(!visible);
        break;
    }
  };

  const toggleToolBar = () => isMobile && setToolBarVisible(!toolBarVisible);

  useImperativeHandle(ref, () => ({
    toggle,
  }));

  const getImageSrc = (item) => {
    if (item && typeof item === 'string') return item;
    if (item && item.url && typeof item.url === 'string') return item.url;
    return undefined;
  };

  /**
   * Handle on pan start
   * @function
   */
  const handleOnPanStart = ({ changedPointers }) => {
    dispatch({
      type: 'pan-start',
      payload: {
        start: changedPointers[0].clientX,
      },
    });
  };

  /**
   * Handle on pan
   * @function
   */
  const handleOnPan = ({ changedPointers }) => {
    const { start, current } = state;
    const delta = ((start - changedPointers[0].clientX) + (current * document.body.clientWidth)) / document.body.clientWidth;

    setAnimated({
      to: { transform: delta },
      immediate: true,
    });

    dispatch({
      type: 'pan',
      payload: { move: delta },
    });
  };

  /**
   * Handle on pan end
   * @function
   */
  const handleOnPanEnd = () => {
    const { move } = state;
    let currentIndex
    if (move > state.index && move - state.index > 0.2) {
      currentIndex = state.index + 1
    } else if (move < state.index && state.index - move > 0.2) {
      currentIndex = state.index - 1
    } else {
      currentIndex = state.index
    }

    let index = 0;
    if (currentIndex < 0) {
      index = 0;
    } else if (currentIndex > imgs.length - 1) {
      index = imgs.length - 1;
    } else {
      index = currentIndex;
    }

    /** current translateX */
    const current = index;

    setAnimated({
      to: { transform: current },
      immediate: false,
    });

    dispatch({
      type: 'pan-end',
      payload: { current, index, move: current },
    });

    onChange(index);
  };

  /**
   * Handle next slide
   * @function
   */
  const handleNextSlide = () => {
    const index = parseInt(state.index, 10) + 1;
    const targetIndex = index <= imgs.length - 1 ? index : parseInt(state.index, 10);

    dispatch({
      type: 'set-index',
      payload: targetIndex,
    });

    setAnimated({
      to: { transform: targetIndex },
      immediate: false,
    });

    onChange(targetIndex);
  };

  /**
   * Handle prev slide
   * @function
   */
  const handlePrevSlide = () => {
    const index = parseInt(state.index, 10) - 1;
    const targetIndex = index >= 0 ? index : 0;

    dispatch({
      type: 'set-index',
      payload: targetIndex,
    });

    setAnimated({
      to: { transform: targetIndex },
      immediate: false,
    });

    onChange(targetIndex);
  };

  /**
   * Handle on close lightbox
   * @param {{}} e
   * @function
   */
  const handleOnClose = (e) => {
    e.stopPropagation();

    setVisible(false);
    onClose();
  };

  const renderImgs = () => (
    <animated.div
      className="lightbox-slider"
      style={{
        transform: animatedProp.transform.interpolate(value => `translateX(${-(value * document.body.clientWidth)}px)`),
      }}
    >
      {
        imgs.map((img, index) => (
          <div key={index.toString()} className="lightbox-image">
            <Img
              src={getImageSrc(img)}
              alt={img ? img.title : ''}
            />
          </div>
        ))
      }
    </animated.div>
  );

  return (
    <Wrapper
      theme={theme}
      onClick={toggleToolBar}
      className={bindingClassName(
        'lightbox',
        {
          'is-open': visible,
          'is-fade-in': visible,
          'is-close': !visible,
          'is-fade-out': !visible,
        },
      )}
    >

      {/* prev slide arrow (display only on desktop) */}
      <div className="left-arrow-container">
        <div
          role="button"
          tabIndex="-1"
          className={bindingClassName('prev-button', { 'is-disabled': state.index === 0 })}
          onKeyPress={() => {}}
          onClick={handlePrevSlide}
        >
          <Icon name="ChevronLeft" />
        </div>
      </div>
      {/* next slide arrow (display only on desktop) */}
      <div className="right-arrow-container">
        <div
          role="button"
          tabIndex="-1"
          className={bindingClassName('next-button', { 'is-disabled': state.index === imgs.length - 1 })}
          onKeyPress={() => {}}
          onClick={handleNextSlide}
        >
          <Icon name="ChevronRight" />
        </div>
      </div>

      <Hammer
        direction="DIRECTION_HORIZONTAL"
        onPanStart={handleOnPanStart}
        onPan={handleOnPan}
        onPanEnd={handleOnPanEnd}
        options={{
          recognizers: {
            pan: {
              enable: isMobile,
            },
          },
        }}
      >
        <div className="lightbox-container">
          <div
            role="button"
            tabIndex="-1"
            onKeyPress={() => {}}
            onClick={e => e.stopPropagation()}
            className={bindingClassName(
              'lightbox-toolbar',
              { 'is-open': toolBarVisible, 'is-close': !toolBarVisible },
            )}
          >
            <div className="lightbox-toolbar-container">
              <div>
                <h5 className="lightbox-toolbar-title">{title}</h5>
                <p className="lightbox-toolbar-sub-title">{imgs[state.index] ? imgs[state.index].filename : ''}</p>
              </div>

              <div
                tabIndex="-1"
                role="button"
                onKeyPress={() => {}}
                onClick={handleOnClose}
                className="close-button"
              >
                <Icon name="Times" />
              </div>
            </div>
          </div>

          <div className="lightbox-body">
            {renderImgs()}
          </div>
        </div>
      </Hammer>
    </Wrapper>
  );
});

Lightbox.propTypes = {
  title: PropTypes.string,
  imgs: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
};

Lightbox.defaultProps = {
  title: '',
  onChange () {},
  onClose () {},
};

const Wrapper = styled.div`
  &.lightbox {
    background-color: ${({ theme }) => theme.colorHelper.black};

    .lightbox-toolbar {
      &-title {
        color: ${({ theme }) => theme.colorHelper.white};
      }

      &-sub-title  {
        color: ${({ theme }) => theme.colorHelper.greyLight};
      }
    }

    .close-button {
      color: ${({ theme }) => theme.colorHelper.white};
    }

    .next-button, .prev-button {
      color: ${({ theme }) => theme.colorHelper.white};
    }
  }
`;

export default Lightbox;
