import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useTransition, animated } from 'react-spring';

import { useTheme } from '~/hooks';
import { useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { categorySelector, shopSelector } from '~/store/selector';
import { slugify } from '~/utils';

/**
 * ----------------------------------------------------------------------------
 * Layer component
 * ----------------------------------------------------------------------------
 *
 * @param {{ units: array, highLightUnits }} props
 * @param {Array} props.units
 * @param {Array} props.highLightUnits
 */
const Layer = ({ units, activeUnits, onClickUnit, fillUnitColor = false }) => {
  const { shops, categories } = useSelector(
    createStructuredSelector({
      shops: shopSelector.shops,
      categories: categorySelector.getOnlyCategories,
    }),
  );
  const { theme } = useTheme();

  const renderFacility = (unit) => {
    const {
      x,
      y,
      icon,
      id,
      width,
      height,
    } = unit;

    return (
      <g key={`${x}${y}`}>
        <image
          id={id}
          xlinkHref={icon}
          x={x-20}
          y={y-50}
          height={height || 0}
          width={width || 0}
          style={{
            pointerEvents: 'none',
            transformOrigin: `${((width) / 2) || 0}px ${((height) / 2) || 0}px`,
          }}
          shapeRendering='geometricPrecision'
        />
      </g>
    );
  };

  const onClickUnitTest = (id, unit) => {
    console.log('onclick on onClickUnitTest >>>> ', id, unit);
  };

  const onClickUnitTouch = (id, unit) => {
    console.log('onclick on onClickUnitTouch >>>> ', id, unit);
  };

  const renderShop = (unit) => {
    const {
      id,
      type,
      'stroke-linecap': strokeLinecap,
      'stroke-miterlimit': strokeMiterlimit,
      'stroke-width': strokeWidth,
      'stroke-linejoin': strokeLinejoin,
      hasShop,
      ...rest
    } = unit;
    const SvgType = type;
    const isActive = activeUnits.find(activeUnit => activeUnit.unitNo === id);

    const shop = shops.find(shop => shop.unit === id);
    const shopCategories = (shop && shop.categories) || [];
    const category = categories.find(cat => cat.id === shopCategories[0]);

    const activeColor = theme.wayfinding.hiLightUnitColor || theme.colorHelper.secondary
    const inactiveColor = fillUnitColor ? category?.fill || 'transparent' : 'transparent'

    const strokeColor = isActive ? 'red' : (inactiveColor === 'transparent' && fillUnitColor) ? 'darkgrey' : 'transparent'

    return (
      <SvgType
        id={id}
        {...rest}
        strokeLinecap={strokeLinecap}
        strokeMiterlimit={strokeMiterlimit}
        strokeWidth={inactiveColor === 'transparent' ? '0.5' : strokeWidth}
        strokeLinejoin={strokeLinejoin}
        fill={isActive ? activeColor : inactiveColor}
        stroke={strokeColor}
        className={isActive ? 'fade-in-out' : ''}
        onClick={() => onClickUnit(id, unit)} // onClickUnit(id, unit)
      />
    );
  };

  /**
   *
   * <SvgType
        id={id}
        {...rest}
        strokeLinecap={strokeLinecap}
        strokeMiterlimit={strokeMiterlimit}
        strokeWidth={strokeWidth}
        strokeLinejoin={strokeLinejoin}
        fill={isActive ? theme.wayfinding.hiLightUnitColor || theme.colorHelper.secondary : 'transparent'}
        stroke={isActive ? 'red' : 'transparent'}
        className={isActive ? 'fade-in-out' : ''}
        onClick={() => onClickUnit(id, unit)}
      />
   */

  const renderUnit = (unit) => {
    const { id, category } = unit;

    return (
      <g key={id || Math.floor(Math.random() * 101)}>
        {category === 'facility' && renderFacility(unit)}
        {category === 'shop' && renderShop(unit)}
      </g>
    );
  };

  return (
    <Wrapper>
      {units.map(renderUnit)}
    </Wrapper>
  );
};

Layer.propTypes = {
  units: PropTypes.arrayOf(PropTypes.shape()),
  activeUnits: PropTypes.arrayOf(PropTypes.shape()),
  onClickUnit: PropTypes.func,
};

Layer.defaultProps = {
  units: [],
  activeUnits: [],
  onClickUnit: () => {},
};

const Wrapper = styled.g``;

export default Layer;
