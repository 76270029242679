import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';

import { bindingClassName } from '~/utils';

/**
 * ----------------------------------------------------------------------------
 * Navbar menu component
 * ----------------------------------------------------------------------------
 *
 * @param {{ children: JSX.Element, isBurgerOpen: Boolean }} props
 * @returns
 */
function Menu ({ children, isBurgerOpen, toggleBurgerBar }) {
  return (
    <div
      className={bindingClassName(
        'navbar-menu',
        { 'is-active': isBurgerOpen },
      )}
    >
      {
        children.map((child, index) => (
          cloneElement(child, { key: index.toString(), isBurgerOpen, toggleBurgerBar })
        ))
      }
    </div>
  );
}

Menu.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  isBurgerOpen: PropTypes.bool,
  toggleBurgerBar: PropTypes.func,
};

Menu.defaultProps = {
  isBurgerOpen: false,
  toggleBurgerBar () {},
};

export default Menu;
