import { createSelector } from 'reselect';
import helper from '../helper';

/**
 * Entire floor
 * @description entire floor from redux store
 */
const entire = helper.getEntireFloor;

/**
 * Floors
 * @description Entire floor which convert to an array
 */
const floors = createSelector(
  [entire],
  entireFloor => Object.entries(entireFloor).reduce((result, floor) => ([ ...result, { id: floor[0], ...floor[1] }]), []).sort((a, b) => {
    if (a.order < b.order) return 1;
    if (a.order > b.order) return -1;
    return 0;
  }),
);

/**
 * Floors
 * @description Entire floor which convert to an array
 */
const getFloorsByBuildingId = createSelector(
  [entire],
  entireFloor => buildingId => Object.entries(entireFloor).reduce((result, floor) => ([ ...result, { id: floor[0], ...floor[1] }]), []).sort((a, b) => {
    if (a.order < b.order) return 1;
    if (a.order > b.order) return -1;
    return 0;
  }).filter(floor => floor.fields.building === buildingId),
);

/**
 * Get floor by id
 * @description Get a single floor by provide id
 * @param {String|Number} id
 */
const getFloorById = createSelector(
  [entire],
  entireFloor => id => entireFloor[id],
);

/**
 * Get floor which prepare to render on floor plan
 * @description Get specific floor which adjust structure for render on floorplan component
 * @param {String|Number} id
 */
const getFloorWhichPrepareToRenderOnFloorPlan = createSelector(
  [ entire, helper.getEntireMedia ],
  (entireFloor, entireMedia) => (id) => {
    if (!entireFloor[id]) return null;
    const {
      fields: { floorplan: src, name, svgHeight, svgWidth },
      elements,
      units,
      order,
      slug,
    } = entireFloor[id];

    /**
     * adjust object structure of facility
     */
    const facilities = elements.map(facility => (
      facility.icon
        ? ({
          ...facility,
          category: 'facility',
          icon: entireMedia[facility.icon] ? entireMedia[facility.icon].url : undefined,
        })
        : {}
    ));

    /**
     * adjust object structure of shop
     */
    const shops = units.map((shop) => {
      const shopHasUnit = units.find(shop => (
        shop.units && shop.units.length > 0
          ? shop.units.some(unit => unit.unitNo === unit.id && unit.floorId === id)
          : undefined
      ));

      if (shopHasUnit && shopHasUnit.logo) {
        return {
          ...shop,
          x: shop.elements.logo.x,
          y: shop.elements.logo.y,
          category: 'shop',
          icon: entireMedia[shopHasUnit.logo] ? entireMedia[shopHasUnit.logo].url : undefined,
          hasShop: true,
        };
      }

      return {
        ...shop,
        category: 'shop',
        hasShop: false,
      };
    });

    return {
      id,
      slug,
      name,
      order,
      shops,
      facilities,
      src: entireMedia[src]?.url,
      viewBox: `0 0 ${svgWidth} ${svgHeight}`,
    };
  },
);

export default {
  entire,
  floors,
  getFloorById,
  getFloorsByBuildingId,
  getFloorWhichPrepareToRenderOnFloorPlan,
};
