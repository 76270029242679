import React from "react";
import styled from "@emotion/styled";
import { useSelector } from "react-redux";
import { createStructuredSelector } from "reselect";

import { Button } from "~/components";

import { useTheme, useTranslation, useRouter } from "~/hooks";

import { domainSelector } from "~/store/selector";
import { useHistory } from "react-router";
import _ from "lodash";

export default ({ icon, title }) => {
  const { theme } = useTheme();
  const { translate, currentLanguage } = useTranslation();
  const { push } = useRouter();
  const { projectId } = useSelector(
    createStructuredSelector({
      projectId: domainSelector.projectId,
    })
  );
  const history = useHistory();
  const floor = window.location.pathname.split('$').slice(-1)[0]
  function isNumeric(value) {
    return /^\d+$/.test(value);
  }
  return (
    <Wrapper className="home-button" theme={theme}>
      <Button
        className="is-secondary"
        size="small"
        leftIcon={icon || "Search"}
        title={title || translate("search")}
        fullWidth={false}
        rounded={false}
        onClick={() => {
          // push({
          //   regexPath: "/:lang/:projectId/explore",
          //   params: {
          //     projectId,
          //     currentLanguage,
          //   },
          // });
          // alert(`floor ${floor} int ${isNumeric(floor)}`)
          history.push(`/${currentLanguage}/${projectId}/explore?floor=${isNumeric(floor) ? floor : '1'}`);
        }}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div``;
