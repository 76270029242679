import sortBy from 'lodash/sortBy';

/* eslint-disable guard-for-in */
import connectRedux from './ConnectRedux';
import actionFetcher from './ActionFetcher';
import fetcher from './Fetcher';
import slugify from './slugify';
import langConverter from './LanguageConverter';

const { PUBLIC_URL } = process.env;

/**
 * Is protocol file function
 * @description for checking protocol is file protocol or not
 * @function
 * @return {Boolean}
 */
export const isProtocolFile = () => (PUBLIC_URL && (PUBLIC_URL.substring(0, 4) === 'file'));

/**
 * Get file
 *
 * @param {String} path path to file on pubic directory
 * @function
 * @return {String}
 */
export const getFile = path => `${PUBLIC_URL}${path}`;

/**
 * Generate binding class name
 *
 * @param {Object<string, boolean>} classNameObject
 * @description binding class name and assign into a string
 * @return {String}
 * @example generateClassName({ active: true, error: false }) => "active"
 */
export const bindingClassName = (...classes) => (
  classes.reduce((result, className) => {
    if (typeof className === 'string') {
      return `${result}${className} `;
    }

    return `${result}${Object.entries(className).reduce((result, [ key, value ]) => `${result}${value ? key : ''} `, '')}`;
  }, '')
);

/**
 * String uniq filter
 *
 * @param {[string || {}]} items
 * @param {{}} condition
 * @return {[string]}
 */
export const uniq = (items, condition) => {
  if (condition) {
    return (
      items.reduce((result, item) => {
        const isDuplicate = result.find(resultItem => resultItem[condition] === item[condition]);

        if (!isDuplicate) {
          return [
            ...result,
            item,
          ];
        }
        return result;
      }, [])
    );
  }

  return (
    items.reduce((result, item) => {
      const isDuplicate = result.find(resultItem => resultItem === item);

      if (!isDuplicate) {
        return [
          ...result,
          item,
        ];
      }
      return result;
    }, [])
  );
};

export const getViewBoxProperty = (viewBox) => {
  const property = viewBox.split(' ');

  return {
    width: parseFloat(property[2]) || 500,
    height: parseFloat(property[3]) || 500,
  };
};

/**
 * Is empty checker
 * @description Check the parameter which provided is empty or not.
 * @param {{}|[]} params can be Object or Array
 * @returns {Boolean}
 */
export const isEmpty = (params) => {
  if (Array.isArray(params)) {
    if (!params || params.length <= 0) {
      return true;
    }
    return false;
  }

  if (!params || Object.getOwnPropertyNames(params).length <= 0) {
    return true;
  }
  return false;
};

/**
 * Is empty array checker
 * @param {Array} arr
 * @deprecated
 */
export const isEmptyArray = (arr) => {
  try {
    if (!arr || arr.length <= 0) {
      return true;
    }

    return false;
  } catch (error) {
    throw new Error(`${arr} is not an array`);
  }
};

/**
 * Random
 *
 * @param {number} [slice=3] - total length of random return number
 * @param {number} [range=10] - total range of random number
 * @returns {Array<number>}
 *
 * @example
 * random(3, 10) -> [ 4, 1, 7 ]
 */
export const random = (slice = 3, range = 10) => {
  if (slice > range) throw new Error(`slice(${slice}) must less than value of range(${range})`);
  const result = [];
  let round = 0;
  while (round < slice) {
    const random = Math.floor(Math.random() * range);
    if (result.indexOf(random) === -1) {
      result.push(random);
      round += 1;
    }
  }

  return result;
};

const reverse = (sourceArray) => {
  if (typeof sourceArray !== 'object' && typeof sourceArray.length !== 'function') {
    console.error('Cannot reverse because', sourceArray, 'is not an array.');
    return sourceArray;
  }
  const reverseArray = [];
  for (let i = sourceArray.length - 1; i >= 0; i -= 1) {
    reverseArray.push(sourceArray[i]);
  }
  return reverseArray;
};

const intersection = (firstArray, secondArray) => firstArray.filter((x) => {
  // checking second array contains the element "x"
  if (secondArray.indexOf(x) !== -1) return true;
  return false;
});

/**
  * Transform array to select option ({ label, value })
  *
  * @param Array<Object>, func, func, Object
  */
const toSelectOptions = (array, label, value) => (
  array.map(item => ({
    ...item,
    label: typeof label !== 'function' ? array[label] : label(item),
    value: typeof value !== 'function' ? array[value] : value(item),
  }))
);

/**
 * Array
 * @description convert Object to Array
 */
const toArray = (object, _sortBy) => {
  const array = Object.entries(object).reduce((result, item) => ([ ...result, { id: item[0], ...item[1] }]), []);
  if (_sortBy === 'desc') {
    return sortBy(array, item => item.order * -1);
  }
  if (_sortBy === 'asc') {
    return sortBy(array, item => item.order);
  }
  return array;
};

/**
 * Text
 * @description substring text by length
 */
const getEllipseText = (text, length) => {
  if (typeof text !== 'string' || text.length < length) {
    return text;
  }
  return `${text.substring(0, length).trim()}...`;
};

export {
  connectRedux,
  actionFetcher,
  fetcher,
  slugify,
  langConverter,
  reverse,
  intersection,
  toSelectOptions,
  toArray,
  getEllipseText,
};

export default {
  getFile,
  isProtocolFile,
  bindingClassName,
  uniq,
  getViewBoxProperty,
  isEmpty,
  langConverter,
  reverse,
  intersection,
  toSelectOptions,
  toArray,
  getEllipseText,
};
