import React from 'react';
import PropTypes from 'prop-types';

import { bindingClassName } from '~/utils';

/**
 * ----------------------------------------------------------------------------
 * Navbar link component
 * ----------------------------------------------------------------------------
 *
 * @param {{ href: String, children: String, uppercase: Boolean, lowercase: Boolean, capitalise: Boolean }} props
 * @returns
 */
function Link ({
  href,
  children,
  uppercase,
  lowercase,
  capitalise,
  target,
}) {
  return (
    <a
      className={bindingClassName(
        'navbar-item',
        { 'is-text-uppercase': uppercase },
        { 'is-text-lowercase': lowercase },
        { 'is-text-capitalise': capitalise },
      )}
      href={href}
      target={target || /.pdf/.test(href) ? '_blank' : ''}
    >
      {children}
    </a>
  );
}

Link.propTypes = {
  href: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  uppercase: PropTypes.bool,
  lowercase: PropTypes.bool,
  capitalise: PropTypes.bool,
  target: PropTypes.string,
};

Link.defaultProps = {
  href: '_blank',
  uppercase: false,
  lowercase: false,
  capitalise: false,
  target: '',
};

export default Link;
