import { useContext } from "react";

import { WayfinderContext } from "~/context/wayfinder";

import { isEmptyArray } from "~/utils";

const separatorChar = "#";

/**
 * Get svg path
 *
 * @param  {{}} node
 */
const getSvgPath = (node) =>
  node.map((v, index) => `${index === 0 ? "M" : "L"}${v.x},${v.y}`).join(",");

/**
 * To walking path
 *
 * @param  {{}} pathSplitByFloor
 */
const toWalkingPath = (pathSplitByFloor) =>
  Object.entries(pathSplitByFloor).reduce((result, [pathKey, nodes]) => {
    const floor = pathKey.split(separatorChar)[1];
    return [
      ...result,
      {
        type: "infloor",
        floor,
        path: getSvgPath(nodes),
      },
    ];
  }, []);

/**
 * Split path by floor
 *
 * @param  {{}} path
 */
const splitPathByFloor = (path) => {
  let sceneNumber = 0;
  let currentSceneFloor;

  return path.reduce((result, node) => {
    /** change floor */
    if (node.floorId !== currentSceneFloor) {
      currentSceneFloor = node.floorId;
      sceneNumber += 1;

      return {
        ...result,
        [`${sceneNumber}${separatorChar}${currentSceneFloor}`]: [node],
      };
    }

    result[`${sceneNumber}${separatorChar}${currentSceneFloor}`].push(node);
    return result;
  }, []);
};

/**
 * Get dijkstra shortest path
 *
 * @param  {{}} graph
 * @param  {{}} from
 * @param  {{}} to
 */
const getDijkstraShortestPath = (graph, from, to) => {
  if (typeof graph.path === "function") {
    return graph.path(from, to, { cost: true });
  }

  console.debug("dijkstra graph is not be constructed");
  return {};
};

/**
 * ----------------------------------------------------------------------------
 * use wayfinder hooks
 * ----------------------------------------------------------------------------
 */
const useWayfinder = () => {
  const { nodes, graph, startPoint, setNodes } = useContext(WayfinderContext);

  /**
   * Get floors in routing
   *
   * @param  {{}} node
   */
  const getFloorsInRouting = (node) =>
    node.reduce((result, node) => {
      const isDuplicate = result.find((item) => item === node);

      if (isDuplicate) {
        return result;
      }

      return result.concat([node.id.split("-")[0]]);
    }, []);

  /**
   * Get node
   *
   * @param  {Number} nodeId
   */
  // const getNode = nodeId => nodes.find((node, key) => key === nodeId) || {};
  const getNode = (id) => nodes[id] || {};

  /**
   * Get node
   *
   * @param  {Object} pathsByFloor
   */
  const getFloorTransition = (pathSplitByFloor) =>
    Object.entries(pathSplitByFloor).reduce((result, [pathKey, nodes]) => {
      const floor = pathKey.split(separatorChar)[1];
      const lastNode = nodes[nodes.length - 1];
      if (!lastNode.isEscalatorNode && !lastNode.isLiftNode) return result;
      return [
        ...result,
        {
          type: lastNode.isEscalatorNode ? "escalator" : "lift",
          floor,
          node: lastNode,
        },
      ];
    }, []);

  /**
   * Get route
   *
   * @param  {{}} from
   * @param  {{}} to
   */
  const getRoute = (from, to) => {
    console.debug("from", from); /** @todo will remove in production */
    console.debug("to", to); /** @todo will remove in production */
    console.debug("nodes", nodes); /** @todo will remove in production */
    console.debug("graph", graph); /** @todo will remove in production */
    const toNode = nodes[to];
    const fromNode = nodes[from];

    if (!toNode || !fromNode) {
      console.error(`fromNode ${from} or toNode ${to} not found.`);
    }

    const { path, cost } = getDijkstraShortestPath(graph, from, to);
    console.debug("dijkstra", { path, cost });
    const routingNodes = path.map((nodeId) => nodes[nodeId]);
    const pathsByFloor = splitPathByFloor(routingNodes);
    const floorTransitions = getFloorTransition(pathsByFloor);
    const routingPaths = toWalkingPath(pathsByFloor);
    return {
      routingNodes,
      routingPaths,
      floorTransitions,
      cost,
      from: fromNode,
      to: toNode,
    };
  };

  /**
   * Get route from kiosk
   *
   * @param  {{}} to
   * @deprecated change name to getRouteFromStartPoint
   */
  const getRouteFromKiosk = (to) => getRoute(startPoint.node || "", to);

  /**
   * Get route from kiosk
   *
   * @param  {{}} to
   */
  const getRouteFromStartPoint = (to) => getRoute(startPoint.node || "", to);

  /**
   * Get movement compare between origin and destination floor
   *
   * @param {Array} routes
   * @function
   * @returns
   */
  const getMovement = ({ origin, destination }) => {
    try {
      return origin && destination && origin.order > destination.order
        ? "down"
        : "up";
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  /**
   * Convert array from getRoute() to object
   *
   * @example
   * input: const routes = [{ floorLevel: 1, floorType: 'origin' }, { floorLevel: 2, floorType: 'destination' }]
   * output: { origin: ..., destination: ... }
   *
   * @param {Array} routes
   * @returns
   */
  const convertToObjectByFloorTypeProperty = (routes) =>
    routes.reduce(
      (result, route) => ({ ...result, [route.floorType]: route }),
      {}
    );

  const renderRoutesFactory = ({
    paths,
    getFloorWhichPrepareToRenderOnFloorPlan,
    getFloorById,
    routingNodes,
    from,
    to,
    originShop,
    destinationShop,
  }) =>
    paths.map(({ floor: floorSlug, path, type }) => {
      const floorplan = getFloorWhichPrepareToRenderOnFloorPlan(floorSlug);
      const { order } = getFloorById(floorSlug); // id equal to floorSlug
      const pins = [];
      const activeUnits = [];
      if (from.floorId === floorSlug) {
        pins.push({ ...from, type: "origin" });
        if (!isEmptyArray(originShop.units)) {
          const unitNoFiltered = originShop.units.filter(
            (unit) => unit.floorId === floorSlug
          );
          unitNoFiltered.map(({ unitNo }) =>
            activeUnits.push({ unitNo, type: "origin" })
          );
        }
      }
      if (to.floorId === floorSlug) {
        pins.push({ ...to, type: "destination" });
        if (!isEmptyArray(destinationShop.units)) {
          const unitNoFiltered = destinationShop.units.filter(
            (unit) => unit.floorId === floorSlug
          );
          unitNoFiltered.map(({ unitNo }) =>
            activeUnits.push({ unitNo, type: "destination" })
          );
        }
      }
      const floorType =
        from.floorId === to.floorId
          ? "origin-destination"
          : from.floorId === floorSlug
          ? "origin"
          : "destination"; /* eslint-disable-line */
      const floorRoutingNodes = routingNodes.filter(
        (node) => node.floorId === floorSlug
      );
      let transition = [];
      const lastNode = floorRoutingNodes[floorRoutingNodes.length - 1];
      if (lastNode.isEscalatorNode || !lastNode.isLiftNode) {
        transition = [
          {
            type: lastNode.isEscalatorNode ? "escalator" : "lift",
            floor: floorSlug,
            node: lastNode,
          },
        ];
      }
      return {
        id: floorSlug,
        floorplan,
        pins,
        path,
        activeUnits,
        routingNodes: floorRoutingNodes,
        type,
        order,
        floorType,
        transition,
      };
    });

  const getOverviewRenderRoutes = ({
    originShop,
    destinationShop,
    getFloorWhichPrepareToRenderOnFloorPlan,
    getFloorById,
  }) => {
    try {
      const {
        routingPaths: paths,
        routingNodes,
        from,
        to,
        floorTransitions,
      } = getRoute(originShop.node, destinationShop.node);
      const pathsWhichFilterOnlyOriginAndDestination = paths.filter(
        (path, index) => index === 0 || index === paths.length - 1
      );
      const renderRoutes = renderRoutesFactory({
        paths: pathsWhichFilterOnlyOriginAndDestination,
        getFloorWhichPrepareToRenderOnFloorPlan,
        getFloorById,
        routingNodes,
        from,
        to,
        originShop,
        destinationShop,
      });
      console.debug("getOverviewRenderRoutes", {
        renderRoutes,
        paths,
        routingNodes,
        from,
        to,
        floorTransitions,
      }); // eslint-disable-line
      renderRoutes.sort((a, b) => b.order - a.order); /** ASC order */
      return renderRoutes;
    } catch (error) {
      throw new Error(error);
    }
  };

  const getStepAndMoreRenderRoutes = ({
    originShop,
    destinationShop,
    getFloorWhichPrepareToRenderOnFloorPlan,
    getFloorById,
  }) => {
    try {
      const {
        routingPaths: paths,
        routingNodes,
        from,
        to,
        floorTransitions,
      } = getRoute(originShop.node, destinationShop.node);
      const renderRoutes = renderRoutesFactory({
        paths,
        getFloorWhichPrepareToRenderOnFloorPlan,
        getFloorById,
        routingNodes,
        from,
        to,
        originShop,
        destinationShop,
      });
      console.debug("getStepAndMoreRenderRoutes", {
        renderRoutes,
        paths,
        routingNodes,
        from,
        to,
        floorTransitions,
      }); // eslint-disable-line
      return renderRoutes;
    } catch (error) {
      throw new Error(error);
    }
  };

  return {
    getFloorsInRouting,
    getRoute,
    getRouteFromKiosk,
    getRouteFromStartPoint,
    getNode,
    setNodes,
    nodes,
    graph,
    startPoint,
    getMovement,
    convertToObjectByFloorTypeProperty,
    getOverviewRenderRoutes,
    getStepAndMoreRenderRoutes,
  };
};

export default useWayfinder;
