import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';

/**
 * ----------------------------------------------------------------------------
 * Navbar end component
 * ----------------------------------------------------------------------------
 *
 * @param {{}} props
 * @returns
 */
function NavbarEnd ({ children, isBurgerOpen, toggleBurgerBar }) {
  return (
    <div className="navbar-end">
      {
        children.reduce((result, child, index) => {
          return (child === undefined) ? result : [
            ...result,
            cloneElement(child, { key: index.toString(), isBurgerOpen, toggleBurgerBar })
          ]
        }, [])
      }
    </div>
  );
}

NavbarEnd.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  isBurgerOpen: PropTypes.bool,
  toggleBurgerBar: PropTypes.func,
};

NavbarEnd.defaultProps = {
  isBurgerOpen: false,
  toggleBurgerBar () {},
};

export default NavbarEnd;
