import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { bindingClassName } from '~/utils';

import Item from './components/Item';
import Icon from './components/Icon';
import { connectAdvanced } from 'react-redux';

let slideTimer;
let scrollAmount = 0;

const slideValue = 200;
const transitionValue = 50;

/**
 * ----------------------------------------------------------------------------
 * Column component
 * ----------------------------------------------------------------------------
 *
 * @param {{
 *  children: JSX.Element,
 *  multiline: Boolean,
 *  centered: Boolean,
 *  left: Boolean,
 *  right: Boolean,
 *  mobileScrollable: Boolean,
 * }} props
 * @returns
 */
function Column ({
  children,
  multiline,
  centered,
  left,
  right,
  verticalAlignTop,
  verticalAlignCenter,
  verticalAlignBottom,
  mobileScrollable,
}) {
  const wrapperEl = useRef(null);

  const [ showLeftArrow, setShowLeftArrow ] = useState(false);
  const [ showRightArrow, setShowRightArrow ] = useState(true);

  const handleScroll = () => {
    const el = wrapperEl.current;
    const minScrollLeft = 0;
    const maxScrollLeft = el.scrollWidth - el.clientWidth;
    const currentScrollLeft = el.scrollLeft;
    if (maxScrollLeft === currentScrollLeft && showRightArrow) setShowRightArrow(false);
    if (maxScrollLeft !== currentScrollLeft && !showRightArrow) setShowRightArrow(true);
    if (minScrollLeft === currentScrollLeft && showLeftArrow) setShowLeftArrow(false);
    if (minScrollLeft !== currentScrollLeft && !showLeftArrow) setShowLeftArrow(true);
  };

  const handleScrollLeft = () => {
    if (wrapperEl && wrapperEl.current) {
      const el = wrapperEl.current;
      slideTimer = setInterval(() => {
        el.scrollLeft -= transitionValue;
        scrollAmount += transitionValue;
        if (scrollAmount >= slideValue) {
          scrollAmount = 0;
          window.clearInterval(slideTimer);
        }
      }, 25);
    }
  };
  const handleScrollRight = () => {
    if (wrapperEl && wrapperEl.current) {
      const el = wrapperEl.current;
      slideTimer = setInterval(() => {
        el.scrollLeft += transitionValue;
        scrollAmount += transitionValue;
        if (scrollAmount >= slideValue) {
          scrollAmount = 0;
          window.clearInterval(slideTimer);
        }
      }, 25);
    }
  };
  return (
    <Container className="column-container">
      <Wrapper
        ref={wrapperEl}
        className={bindingClassName(
          'column', {
            'is-centered': centered,
            'is-left': left,
            'is-right': right,
            'is-vertical-align-top': verticalAlignTop,
            'is-vertical-align-center': verticalAlignCenter,
            'is-vertical-align-bottom': verticalAlignBottom,
            'is-multiline': multiline,
            'is-mobile-scrollable': mobileScrollable,
          },
        )}
        onScroll={handleScroll}
      >
        {children}
      </Wrapper>
      {mobileScrollable && children.length > 1 && (
        <ArrowContainer>
          <ArrowWrapper
            className={bindingClassName(
              'arrow-wrapper left', {
                hide: !showLeftArrow,
              },
            )}
            onClick={handleScrollLeft}
          >
            <Icon className="arrow" name="ChevronLeft" />
          </ArrowWrapper>
          <ArrowWrapper
            className={bindingClassName(
              'arrow-wrapper right', {
                hide: !showRightArrow,
              },
            )}
            onClick={handleScrollRight}
          >
            <Icon className="arrow" name="ChevronRight" />
          </ArrowWrapper>
        </ArrowContainer>
      )}
    </Container>
  );
}

Column.Item = Item;
Column.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  multiline: PropTypes.bool,
  centered: PropTypes.bool,
  left: PropTypes.bool,
  right: PropTypes.bool,
  verticalAlignTop: PropTypes.bool,
  verticalAlignCenter: PropTypes.bool,
  verticalAlignBottom: PropTypes.bool,
  mobileScrollable: PropTypes.bool,
};

Column.defaultProps = {
  multiline: false,
  centered: false,
  left: false,
  right: false,
  verticalAlignTop: false,
  verticalAlignCenter: false,
  verticalAlignBottom: false,
  mobileScrollable: false,
};
const Container = styled.div`
`;
const Wrapper = styled.div`
`;
const ArrowContainer = styled.div`
`;
const ArrowWrapper = styled.div`
`;

export default Column;
